document.addEventListener("DOMContentLoaded", function () {
	$(document).ready(function () {
		const path = window.location.pathname;
		if (
			[
				"/media-and-advertising-contract/list",
				"/vendor-data-services-contract/list",
				"/freight-allowance-addendum/list",
			].includes(path)
		) {
			$(".web-list-header").hide();
			const listContainer = $(".web-list-container");
			$(".page_content").css({ "max-width": "none" });
			if (listContainer.length > 0) {
				const tabs = ["Action Needed", "Pending", "Completed", "Expired", "Rejected"];
				const newItem = $("<div>", { class: "rebate-contract-filters" });
				createTabs(tabs, newItem, "#workflow_state");
				listContainer.children().eq(0).after(newItem);
			}
		}
	});
});

function createTabs(tabs, container, targetField) {
	const buttons = tabs.map((text) => $("<button>").text(text));
	container.html(buttons);

	container.on("click", "button", function () {
		const tabText = $(this).text().toLowerCase().replace(" ", "_");
		$(this).addClass("selected").siblings().removeClass("selected");
		$(targetField).val(tabText);
		// const dropdown = $(`[data-fieldname="${targetField.substring(1)}"] select`);
		// dropdown.val(tabText).trigger("change");
	});

	$(document).on("change", `[data-fieldname="${targetField.substring(1)}"] select`, function () {
		const selectedValue = $(this).val();
		const tab = container.find("button").filter(function () {
			return $(this).text().toLowerCase().replace(" ", "_") === selectedValue;
		});
		tab.addClass("selected").siblings().removeClass("selected");
	});
}
