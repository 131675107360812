document.addEventListener("DOMContentLoaded", function () {
	$(document).ready(function () {
		const path = window.location.pathname;
		if (path === "/web-item-price/list") {
			// Get selected rows
			get_selected_row_id();
			create_manage_costs_redirect_tabs();
			$(".page_content").css({ "max-width": "none" });
			$(".web-list-header").css({
				display: "flex",
				"justify-content": "space-between",
				"align-items": "center",
				"margin-top": "3%",
			});
			$(".web-list-title")
				.html(
					"<h3 style='display: contents'>Cost Update Request</h3><br><br> This request process supports changes to Chewy’s purchasing cost, minimum advertised price (MAP), and manufacturer’s suggested retail price (MSRP). To change unit of measure (UOM) or minimum order quantity (MOQ), please submit a ticket under <a style='color:blue' href='https://vpp.chewy.com/admin/vendors/vsp/create'>Vendor Services.<a><br><br>This view includes active items only. If an item appears to be missing from this list,<a style='color:blue' href='https://vpp.chewy.com/home'> View Catalog. </a>"
				)
				.css({
					flex: "3",
					"text-align": "left",
				});
			$(".web-list-header .ellipsis").css({ "white-space": "pre-line" });

			// Initialize FilterGroup
			initialize_filter_group();
		}
	});
});

// Get selected row item code id to get filtered items
function get_selected_row_id() {
	$(document).on("change", '.list-col-checkbox input[type="checkbox"], .select-all', function () {
		let selected_items = [];

		// Iterate over rows and collect selected items
		$("tr").each(function () {
			let checkbox = $(this).find('.list-col-checkbox input[type="checkbox"]');
			if (checkbox.is(":checked")) {
				let item_code = $(this).find("td:eq(4) .ellipsis").text().trim();
				selected_items.push({ item_code });
			}
		});

		// Check if any items are selected
		if (selected_items.length > 0) {
			// Only append the button if it doesn't already exist
			if ($(".web-list-header .btn").length === 0) {
				$(".web-list-header").append(
					"<button style='padding-left: 40px;padding-right: 40px' class='btn btn-primary rounded-pill mt-2'>Update Costs</button>"
				);
			}

			$(".web-list-header .btn")
				.off("click")
				.on("click", function () {
					map_sku_items(selected_items);
				});
		} else {
			// Remove the button and hide the header if no items are selected
			$(".web-list-header").find(".btn").remove();
		}
	});
}

// Get item details from Item and Item Price doctype and append in sku child table in Cost Update webform
function map_sku_items(selected_items) {
	frappe.call({
		method: "erpnext_custom_app.cost_update.web_form.item_price.item_price.get_item_details",
		args: {
			filtered_items: selected_items,
		},
		callback: function (response) {
			if (response.message) {
				// Store items data in session storage
				sessionStorage.setItem("items_data", JSON.stringify(response.message));
				window.location.href = "/cost-update-request/new?is_excel_upload=0"; // Replace with actual page path
			}
		},
	});
}

// Function to handle unchecking checkboxes and resetting the UI when filters are applied
function reset_selection_on_filter() {
	// Uncheck all checkboxes
	$('.list-col-checkbox input[type="checkbox"]').prop("checked", false);
}

function initialize_filter_group() {
	// Initialize the FilterGroupWeb
	let filter_group = new frappe.ui.FilterGroupWeb({
		parent: $(".web-list-filters"),
		doctype: "Item Price",
		fieldname: "brand",
		filter_fields: [
			{
				label: "Brands",
				fieldname: "brand",
				fieldtype: "Link",
				options: "Brand",
				parent: "Item Price",
			},
			{
				label: "UPC",
				fieldname: "custom_upc",
				fieldtype: "Data",
				parent: "Item Price",
			},
			{
				label: "Vendor ID",
				fieldname: "custom_site_id",
				fieldtype: "Link",
				options: "Address",
				parent: "Item Price",
			},
			{
				label: "VPN",
				fieldname: "custom_vendor_part_number",
				fieldtype: "Data",
				parent: "Item Price",
			},
		],
		on_change: () => {
			// Reset rows selection after filters applied
			reset_selection_on_filter();
			// Reset filters
			frappe.web_form_list.filters = {};

			// Process new filters
			let filter_group_filters = filter_group.get_filters().reduce((filters, item) => {
				if (item[3]) {
					filters.push(item.slice(1, 4));
					frappe.web_form_list.filters[item[1]] = [item[2], item[3]];
				}
				return filters;
			}, []);

			// Refresh the list with new filters
			frappe.web_form_list.refresh();
		},
	});

	// Show the filter group
	$(".web-list-filters").removeClass("hide");
}

function create_manage_costs_redirect_tabs() {
	const helpdeskIcon = $(".navbar-nav .nav-item.dropdown").first().detach();

	// Append the custom buttons
	$(".web-list-container")
		.prepend(
			`
        <div id="actions-container" class="col-12" style="height:45px;">
            <div style="height:100%; display:inline-block; background-color: transparent;" id="default-tab">
                <a href="/manage_costs"><button class="tab-button" id="request-cost-update" style="color:grey; background-color: transparent; border: none; cursor: pointer;">Request Cost Update</button></a>
            </div>
            <div style="height:100%; display:inline-block; background-color: transparent;">
                <a href="/manage_costs#history-tab"><button class="tab-button" id="cost-update-history" style="color:grey; background-color: transparent; border: none; cursor: pointer;">Cost Update History</button></a>
            </div>
			<div id="helpdesk"  style="display:inline-block;"></div>
        </div>
    `
		)
		.css({ "max-width": "1604px" });

	$("#helpdesk").append(helpdeskIcon);
	$(helpdeskIcon).css({
		display: "inline-block",
	});

	// jQuery to handle button activation and dropdown functionality
	$("#actions-container button.tab-button").on("click", function () {
		// Remove active state (color and border) from all buttons and divs
		$("#actions-container button.tab-button").css({
			color: "grey",
			"background-color": "transparent",
		});
		$("#actions-container div").css({
			"border-bottom": "none",
		});

		// Add active state (black color for button and blue underline for div) to the clicked button and its parent div
		$(this).css({
			color: "black",
			"background-color": "transparent", // optional, can add background if needed
		});

		$(this).closest("div").css({
			"border-bottom": "3px solid #0d6efd", // Apply blue underline to div
		});
	});

	// Set the default tab style (black color for the button and blue underline for its parent div)
	$("#request-cost-update").css({
		color: "black",
	});
	$("#default-tab").css({
		"border-bottom": "3px solid #0d6efd", // Default blue underline
	});
}
